import React from 'react'
import { Grid, Typography, ButtonBase } from '@material-ui/core';
import Scrollbar from 'react-scrollbars-custom';
import { Spring } from 'react-spring/renderprops';
import SvgCloseIcon from '../SvgCloseIcon';

export default function Lyrics(props) {

    const {
        classes,
        tracks,
        currentTrackIndex,
        _toggleLyrics,
        currentLyricIndex
    } = props;

    console.log(currentLyricIndex)

    // console.log(tracks[currentTrackIndex - 1].verses)

    return (
        <Grid container spacing={0} justify="center" alignItems="center" alignContent="center" style={{ height: '100%', zIndex:1100 }} className={classes.leftContentWrapper}>
            <div className={classes.closeButton}>
                <ButtonBase onClick={ () => _toggleLyrics()} className={classes.closeButtonIcon}>
                    <SvgCloseIcon size={24} />
                </ButtonBase>
            </div>
            <Scrollbar style={{ width: '100%', height: '100%' }} >
                <Spring
                    from={{opacity:0}}
                    to={{opacity:1}}
                >
                    {props => (
                        <div style={props}>
                            <Grid item className={classes.lyricsTitle}>
                                <Typography variant="h5">
                                    {tracks[currentLyricIndex - 1].number}<span style={{ marginRight: 16 }}>.</span>
                                    {tracks[currentLyricIndex - 1 ].title}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.verseWrapper}>
                                {tracks[currentLyricIndex - 1].lyrics.map((verse, verseIndex) =>
                                    <div key={verseIndex} className={classes.lyricsVerse}>
                                        {verse.group.map((row, rowIndex) =>
                                            <div key={rowIndex} className={classes.lyricContent}>
                                                <Typography variant="body2">{row.title}</Typography>
                                                <Typography variant="body2">{row.row}</Typography>
                                            </div>
                                        )}
                                    </div>)}
                            </Grid>
                        </div>
                    )}
                </Spring>
            </Scrollbar>
        </Grid>
    )
}
