import React, { Component } from 'react'

export class index extends Component {

    constructor(props){
        super(props);

        this.handleSliderMove = this.handleSliderMove.bind(this);
        this.deactivateSlider = this.deactivateSlider.bind(this);
        this.activateSlider = this.activateSlider.bind(this);
        this.setProgress = this.setProgress.bind(this);

        this.state={
            progress: 0
        }
    }

    componentDidMount() {
        document.querySelector('.progress-slider').addEventListener('mousedown', this.activateSlider)
        document.querySelector('.progress-slider').addEventListener('touchstart', this.activateSlider)
        window.addEventListener('mousemove', this.handleSliderMove);
        window.addEventListener('mouseup', this.deactivateSlider);
        window.addEventListener('touchmove', this.handleSliderMove);
        window.addEventListener('touchend', this.deactivateSlider);
        window.activeSlider = null;
    }

    componentDidUpdate(){
        var progressFill = document.getElementsByClassName('progress-fill')[0];
        progressFill.style.transform = 'scaleX(' + this.props.percentagePlayed / 100 + ')';
    }

    mouseSliderPosition(element, e) {
        var bcr = element.getBoundingClientRect();
        return {
            x: Math.min(Math.max(0, (e.clientX - bcr.left) / bcr.width), 1),
            y: Math.min(Math.max(0, (e.clientY - bcr.top) / bcr.height), 1)
        }
    };
    
    activateSlider(e) {
        if (e.touches && e.touches.length > 1) {
            return;
        }
        e.preventDefault();
    
        this.activeSlider = this;
        this.handleSliderMove(e);
    }
    
    
    handleSliderMove(e) {
        if (e.touches && e.touches.length > 1) {
            return;
        }
        if (this.activeSlider) {
            var progressBar = document.getElementsByClassName('progress-bar')[0];
            var progressFill = document.getElementsByClassName('progress-fill')[0];
            var value = this.mouseSliderPosition(progressBar, e.touches && e.touches[0] || e).x;
            progressFill.style.transform = 'scaleX(' + value + ')';
            this.props._seekTo(value)
        }
    }
    
    deactivateSlider(e) {
        if (e.touches && e.touches.length > 0) {
            return;
        }
        this.activeSlider = null;
    }


    setProgress(progress) {


    }

    render() {

        const {
            percentagePlayed
        } = this.props;

        return (
            <div className="progress-slider">
                <div className="progress-bar"><div className="progress-fill"></div></div>
            </div>
        )
    }
}

export default index
