import React, { Component } from 'react';
import { Grid, Typography, ButtonBase } from '@material-ui/core';
import { Spring } from 'react-spring/renderprops';
import { BrowserView, MobileView } from 'react-device-detect';
import SpeechBubble from './SpeechBubbleIcon';

export class TrackList extends Component {

    render() {

        const {
            tracks,
            classes,
            currentTrackIndex,
            _init,
            _switchSong,
            _toggleLyrics
        } = this.props;

        return (
            <>
                <BrowserView>
                    <Grid container spacing={0} className={classes.trackListWrapper}>
                        <Grid item>
                            <ul className={classes.trackList}>
                                {
                                    tracks.map(track => <li
                                        key={track.number}
                                        style={{width:350}}
                                        className={classes.trackListItemWrapper}>
                                            <div
                                                className={classes.trackListItem}
                                                style={{ color: (currentTrackIndex === track.number) ? '#F94D4F' : '' }}
                                                onClick={() => _switchSong(track.number)}>
                                                <span className={classes.trackListItemNumberLeft}>{track.number}.</span> <span className={classes.trackListItemText}>{track.title}</span>
                                            </div>
                                    </li>)
                                }
                            </ul>
                        </Grid>
                    </Grid>
                </BrowserView>
                <MobileView>
                    <Grid container spacing={0} className={classes.trackListWrapper}>
                        <Grid item>
                            <ul className={classes.trackList}>
                                {
                                    tracks.map(track => <li
                                        key={track.number}
                                        className={classes.trackListItemWrapper}>
                                            <Grid
                                                container
                                                spacing={0}
                                                className={classes.trackListItem}
                                                style={{ color: (currentTrackIndex === track.number) ? '#1be9f1' : '' }}>
                                                <Grid item style={{width:300}} onClick={() => _switchSong(track.number)}>
                                                    <span className={classes.trackListItemNumberLeft}>{track.number}.</span> <span className={classes.trackListItemText}>{track.title}</span>
                                                </Grid>
                                                <Grid item style={{marginLeft:16}}>
                                                    <ButtonBase onClick={ () => _toggleLyrics(track.number)}>
                                                        <SpeechBubble />
                                                    </ButtonBase>
                                                </Grid>
                                            </Grid>
                                    </li>)
                                }
                            </ul>
                        </Grid>
                    </Grid>
                </MobileView>
            </>
        )
    }
}

export default TrackList
