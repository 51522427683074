import React from 'react'

export default function SpeechBubble() {
    return (
        <div style={{ width: 16, height: 16 }}>
            <svg id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 489.3 489.3' fill="#fff">
                <path className='st0' d='M224.3,369.3c-6.8,0-12.3-5.5-12.3-12.3s5.5-12.3,12.3-12.3h226.6c7.7,0,13.9-6.2,13.9-13.9V77.3 c0-7.7-6.2-13.9-13.9-13.9H38.3c-7.7,0-13.9,6.2-13.9,13.9V331c0,7.7,6.2,13.9,13.9,13.9h30.2c6.8,0,12.3,5.5,12.3,12.3V418 l69.5-69.5c4.8-4.8,12.5-4.8,17.3,0s4.8,12.5,0,17.3l-78.9,78.9c-3.6,3.6-8.5,5.6-13.4,5.6c-2.4,0-4.9-0.5-7.3-1.4 c-7.1-2.9-11.7-9.8-11.7-17.5v-61.9H38.4C17.2,369.5,0,352.3,0,331.1V77.3c0-21.2,17.2-38.4,38.4-38.4h412.5 c21.2,0,38.4,17.2,38.4,38.4V331c0,21.2-17.2,38.4-38.4,38.4H224.3V369.3z'
                />
                <path className='st0' d='M401.2,152.9H88c-6.8,0-12.3-5.5-12.3-12.3s5.5-12.3,12.3-12.3h313.2c6.8,0,12.3,5.5,12.3,12.3 S408,152.9,401.2,152.9z'
                />
                <path className='st0' d='M401.2,218.8H88c-6.8,0-12.3-5.5-12.3-12.3s5.5-12.3,12.3-12.3h313.2c6.8,0,12.3,5.5,12.3,12.3 S408,218.8,401.2,218.8z'
                />
                <path className='st0' d='M244.6,284.6H88c-6.8,0-12.3-5.5-12.3-12.3s5.5-12.3,12.3-12.3h156.6c6.8,0,12.3,5.5,12.3,12.3 C256.8,279.1,251.4,284.6,244.6,284.6z'
                />
            </svg>
        </div>
    )
}
