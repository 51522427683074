import React from 'react'
import { BrowserView, MobileView } from 'react-device-detect';
import { Grid, ButtonBase, Typography } from '@material-ui/core';
import PlayIcon from './PlayIcon'
import PauseIcon from './PauseIcon'
import PreviousIcon from './PreviousIcon'
import NextIcon from './NextIcon'
import ProgressBar from '../ProgressBar'


class AudioPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {

        const {

        } = this.state;

        const {
            currentTrackIndex,
            tracks,
            classes,
            playing,
            audioCurrentTimeHtml,
            percentagePlayed,
            audioDurationHtml,
            _togglePlayPause,
            _seekTo,
            _nextSong
        } = this.props;
    
        return (
            <>
            <BrowserView style={{width:'100%'}}>

                <Grid container spacing={0} justify="center" alignItems="center" alignContent="center" className={classes.playerControlsWrapper}>
                    <Grid item>
                        <ButtonBase onClick={ () => _nextSong(currentTrackIndex - 1)}>
                            <PreviousIcon size={24} />
                        </ButtonBase>
                    </Grid>
                    <Grid item >
                        <ButtonBase className={classes.playButtonWrapper} onClick={() => _togglePlayPause()}>
                            {playing ? <PauseIcon className={classes.playIcon} size={60} /> : <PlayIcon size={60} className={classes.playIcon} />}
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <ButtonBase onClick={ () => _nextSong(currentTrackIndex + 1)}>
                            <NextIcon size={24} />
                        </ButtonBase>
                    </Grid>
                </Grid>
                <Grid container spacing={0} justify="center" alignItems="center" alignContent="center">
                    <Typography variant="caption">{audioCurrentTimeHtml}</Typography>
                    <ProgressBar _seekTo={_seekTo} percentagePlayed={percentagePlayed} />
                    <Typography variant="caption">{audioDurationHtml}</Typography>
                </Grid>

            </BrowserView>
            <MobileView style={{width:'100%'}}>
                <Grid container spacing={0} justify="center" alignItems="center" alignContent="center" className={classes.playerControlsWrapper}>
                    <Grid item>
                        <ButtonBase onClick={ () => _nextSong(currentTrackIndex - 1)}>
                            <PreviousIcon size={24} />
                        </ButtonBase>
                    </Grid>
                    <Grid item >
                        <ButtonBase className={classes.playButtonWrapper} onClick={() => _togglePlayPause()}>
                            {playing ? <PauseIcon className={classes.playIcon} size={36} /> : <PlayIcon className={classes.playIcon} size={36} />}
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <ButtonBase onClick={ () => _nextSong(currentTrackIndex + 1)}>
                            <NextIcon size={24} />
                        </ButtonBase>
                    </Grid>
                </Grid>
                <Grid container spacing={0} justify="center" alignItems="center" alignContent="center">
                    <Typography variant="caption">{audioCurrentTimeHtml}</Typography>
                    <ProgressBar _seekTo={_seekTo} percentagePlayed={percentagePlayed} />
                    <Typography variant="caption">{audioDurationHtml}</Typography>
                </Grid> 
            </MobileView>
            </>
        )
    }
}

export default AudioPlayer