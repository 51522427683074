import React from 'react'

export default function FacebookIcon() {
    return (
        <div style={{ width: 24, height: 24 }}>
            <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 455 455' fill="#fff">
                <path d='M0,0v455h455V0H0z M301.004,125.217H265.44	c-7.044,0-14.153,7.28-14.153,12.696v36.264h49.647c-1.999,27.807-6.103,53.235-6.103,53.235h-43.798V385h-65.266V227.395h-31.771	v-53.029h31.771v-43.356c0-7.928-1.606-61.009,66.872-61.009h48.366V125.217z'
                    fillRule='evenodd' clipRule='evenodd' />
            </svg>
        </div>
    )
}
