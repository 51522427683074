import React from 'react'
import { Spring } from 'react-spring/renderprops';

export default function Artwork(props) {

    const {
        classes
    } = props;

    return (
        <Spring
            from={{opacity:0}}
            to={{opacity:1}}
            config={{delay:100}}
        >
            {props => (
                <div style={props} className={classes.artworkWrapper}>
                </div>
            )}
        </Spring>
    )
}
