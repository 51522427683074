import React from 'react';

const CloseIcon = (props) => {

    const {
        size
    } = props;

    return (
        <div style={{width: size, height:size, margin:'0 auto'}}>
            <svg viewport={`0 0 ${size} ${size}`} version="1.1" width={size} height={size}
                xmlns="http://www.w3.org/2000/svg">
                <line x1="1" y1={`${size}`} x2={`${size}`} y2="1" stroke="white" strokeWidth="2"/>
                <line x1="1" y1="1" x2={`${size}`} y2={`${size}`}stroke="white" strokeWidth="2"/>
            </svg>
        </div>
     )
  }

export default CloseIcon
