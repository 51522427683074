import React, { Component } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { AuthUserContext, withAuthentication } from '../Session';
import { BrowserView, MobileView } from 'react-device-detect';
import LoginForm from '../LoginForm';
import tracks from '../../tracks.json';
import Artwork from '../Artwork';
import ArtworkImage from '../../assets/img/artwork.jpg'
import ArtworkMobile from '../../assets/img/artwork-mobile.jpg'
import ArtworkBackground from '../../assets/img/artwork_blank.jpg'
import PlayerBg from '../../assets/img/bg-player.jpg'
import TitleBackground from '../../assets/img/bg-title.jpg'
import LoginBg from '../../assets/img/bg-login.jpg';
import MsmLogo from '../../assets/img/msm-logo.png';
import SonyLogo from '../../assets/img/sony-logo.png';
import Title from '../Title';
import Socials from '../Socials';
import Logo from '../Logo';
import TrackList from '../TrackList';
import Player from '../Player';
import Lyrics from '../Lyrics';
import ReactPlayer from 'react-audio-player'
import { Spring } from 'react-spring/renderprops';
import Scrollbar from 'react-scrollbars-custom';
import { detect } from 'detect-browser';
import {
    Grid,
    Button,
    Typography
} from '@material-ui/core';
import Track1 from '../../assets/mp3/01_Inte meningen (intro)_lowered for context_eq 05-14.mp3'
import Track2 from '../../assets/mp3/02_Trophy (ft Imenella)_rvsd 02-27.mp3'
import Track3 from '../../assets/mp3/03_Hennessy (ft Lorentz)_eq 05-14.mp3'
import Track4 from '../../assets/mp3/04_Ajajaj_eq 05-14.mp3'
import Track5 from '../../assets/mp3/05_Ljuset i tunneln (ft. Lorentz)_(Akta Ljuset Prov)_rvsd 05-14.mp3'
import Track6 from '../../assets/mp3/06_Primadonna_(Lilliana)_eq 03-25.mp3'
import Track7 from '../../assets/mp3/07_Aladdin (ft Ikhana)_lowered for context_05-14.mp3'
import Track8 from '../../assets/mp3/08_Azonto (vocal up)_eq 08-23.mp3'
import Track9 from '../../assets/mp3/09 Hur manga ganger - HMG (ft Lorentz_eq 05-29.mp3'
import Track10 from '../../assets/mp3/10 Slalom_eq 05-29.mp3'
import Track11 from '../../assets/mp3/11_Luftgitarr - Trubaduren (ft Arif)_lowered for context_eq 05-14.mp3'
import Track12 from '../../assets/mp3/12_Dalen - Dimman_eq 05-14.mp3'
import Track13 from '../../assets/mp3/13 Se pa mig (ft Erik Lundin_eq 05-29.mp3'
import SpotifyIcon from '../Socials/SpotifyIcon'

const styles = theme => ({
    loginTitle: {
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.h4.fontSize
        }
    },
    loginSubtitle1: {
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.caption.fontSize
        }
    },
    loginWrapper: {
        height: '100vh',
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
        padding: theme.spacing(3),
        background: `url(${LoginBg}) no-repeat center`,
    },
    loginGridItem: {
        width: '100%',
        margin: '16px 0'
    },
    loginInput: {
        textAlign: 'center',
    },
    pageTitleWrapper: {
        padding: theme.spacing(3),
        background: `url(${TitleBackground}) no-repeat center`,
        // background: 'black',
        zIndex:1200,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
            background: 'transparent',
        }
    },
    artworkWrapper: {
        background: `url(${ArtworkImage}) no-repeat center`,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        display: 'block'
    },
    trackListWrapper: {
        padding: theme.spacing(6),
        width:'100%',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    trackListItemWrapper: {
        margin: theme.spacing(1, 0),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0),
        }
    },
    trackListItem: {
        transition: 'all .3s ease',
        [theme.breakpoints.up('sm')]: {
            "&:hover": {
                color: '#F94D4F',
                cursor: 'pointer'
            }
        }
    },
    trackList: {
        listStyle: 'none',
        margin: 0,
        // paddingLeft: 90,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0
        }
        // width: '100%'
    },
    pageTitle: {
        letterSpacing: 3,
        textAlign: 'center',
    },
    trackListItemText: {
        fontSize: theme.typography.subtitle1.fontSize,
        letterSpacing: 1.3,
        fontFamily: theme.typography.h5.fontFamily,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.button.fontSize,
        }
    },
    trackListItemNumberRight: {
        fontSize: theme.typography.subtitle1.fontSize,
        marginLeft: theme.spacing(1),
        fontFamily: theme.typography.h5.fontFamily,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.button.fontSize,
        }
    },
    trackListItemNumberLeft: {
        fontSize: theme.typography.subtitle1.fontSize,
        marginRight: theme.spacing(1),
        fontFamily: theme.typography.h5.fontFamily,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.button.fontSize,
        }
    },
    playerControlsWrapper: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(4)
        }
    },
    playButtonWrapper: {
        borderRadius: '50%',
        margin: theme.spacing(0, 6),
    },
    playIcon: {
        width: 30,
        height: 30,
        [theme.breakpoints.down('sm')]: {
            width: 36,
            height: 36,
        }
    },
    socialIcon: {
        display: 'block',
        transition: 'all .3s ease-in-out',
        "&:hover": {
            transform: 'translateX(2px)'
        }
    },
    mobileWrapper: {
        background: `url(${ArtworkMobile}) no-repeat center`,
        backgroundSize: 'cover',
        // height: '100%',
        paddingBottom:theme.spacing(4)
    },
    leftContentWrapper: {
        position:'absolute',
        top:0,
        left:0,
        display:'block',
        width:'100%',
        height:'100%',
        background:`url(${ArtworkBackground}) no-repeat center`,
        backgroundSize:'cover'
    },
    rightColumnWrapper: {
        background:`url(${PlayerBg}) no-repeat center`,
        backgroundSize:'cover'
    },
    lyricsTitle:{
        width:'100%',
        margin: theme.spacing(8, 0, 5, 0),
        textAlign:'center',
        [theme.breakpoints.down('sm')]: {
            textAlign:'left',
            margin: theme.spacing(8, 2, 5, 2),
        }
    },
    lyricsVerse: {
        maxWidth:600,
        margin:'0 auto',
        paddingBottom: theme.spacing(4)
    },
    verseWrapper: {
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2, 8, 2)
        }
    },
    lyricContent: {
        textAlign:'center',
        [theme.breakpoints.down('sm')]: {
            textAlign:'left',
        }
    },
    closeButton: {
        position:'absolute',
        top:theme.spacing(5),
        right: theme.spacing(5),
        zIndex:1200,
        transition: 'all .3s ease',
        "&:hover": {
            transform: 'rotate(-90deg)'
        },
        [theme.breakpoints.down('sm')]: {
            top:theme.spacing(3),
            right: theme.spacing(3)
        }
    },
    socialItem: {
        width:32,
        [theme.breakpoints.down('sm')]:{
            width:50
        }
    },
    preSaveButtonWrapper: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        // bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex:1100
    },
    releaseInfoWrapper: {
        position:'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
    }
})

export class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tracks: tracks,
            currentTrackIndex: 1,
            currentTrack: Track1,
            playing: false,
            percentagePlayed: 0,
            audioCurrentTimeHtml: '0:00',
            audioDurationHtml: '0:00',
            init: false,
            displayLyrics: false,
            currentLyricIndex: 1
        }

        this.rap = React.createRef();
        this.browser = detect();

        this._seekTo = this._seekTo.bind(this);
        this._init = this._init.bind(this);
        this._nextSong = this._nextSong.bind(this);
        this._togglePlayPause = this._togglePlayPause.bind(this);
        this._switchSong = this._switchSong.bind(this);
        this._toggleLyrics = this._toggleLyrics.bind(this);
        this._togglePreSave = this._togglePreSave.bind(this);
    }

    _init() {
        this.setState({
            init: true
        })
    }

    _toggleLyrics(index) {

        if(index) {
            this.setState({
                currentLyricIndex: index,
                displayLyrics: !this.state.displayLyrics
            })
        } else {
            this.setState({
                displayLyrics: !this.state.displayLyrics
            })            
        }


    }

    _togglePlayPause() {

        const {
            playing
        } = this.state;

        if (playing) {
            this.rap.audioEl.pause()
            this.setState({
                playing: false
            })
        } else {
            this.rap.audioEl.play()
            this.setState({
                playing: true
            })
        }

        this._init()
    }

    _nextSong(index) {

        var newIndex;
        var nextSong;


        if (index > 13) {
            newIndex = 1
        } else if (index === 0) {
            newIndex = 1;
            this.rap.audioEl.currentTime = 0;
        }
        else {
            newIndex = index
        }

        switch (newIndex) {
            case 1:
                nextSong = Track1;
                break
            case 2:
                nextSong = Track2;
                break
            case 3:
                nextSong = Track3;
                break
            case 4:
                nextSong = Track4;
                break
            case 5:
                nextSong = Track5;
                break
            case 6:
                nextSong = Track6;
                break
            case 7:
                nextSong = Track7;
                break
            case 8:
                nextSong = Track8;
                break
            case 9:
                nextSong = Track9;
                break
            case 10:
                nextSong = Track10;
                break
            case 11:
                nextSong = Track11;
                break
            case 12:
                nextSong = Track12;
                break
            case 13:
                nextSong = Track13;
                break
            case index > 13:
                nextSong = Track1
                break
            default:
                nextSong = Track1;
        }

        this.setState({
            currentTrack: nextSong,
            currentTrackIndex: newIndex
        })

    }

    _switchSong(index) {
        var nextSong;

        switch (index) {
            case 1:
                nextSong = Track1;
                break
            case 2:
                nextSong = Track2;
                break
            case 3:
                nextSong = Track3;
                break
            case 4:
                nextSong = Track4;
                break
            case 5:
                nextSong = Track5;
                break
            case 6:
                nextSong = Track6;
                break
            case 7:
                nextSong = Track7;
                break
            case 8:
                nextSong = Track8;
                break
            case 9:
                nextSong = Track9;
                break
            case 10:
                nextSong = Track10;
                break
            case 11:
                nextSong = Track11;
                break
            case 12:
                nextSong = Track12;
                break
            case 13:
                nextSong = Track13;
                break
            case index > 13:
                nextSong = Track1
                break
            default:
                nextSong = Track1;
        }


        if (!this.state.init) {
            this.rap.audioEl.play()
            this.setState({
                currentTrack: nextSong,
                currentTrackIndex: index,
                playing: true,
                init: true
            })
        } else if (!this.state.playing) {
            this.setState({
                playing: true,
                currentTrack: nextSong,
                currentTrackIndex: index,
            })
        }
        else {
            this.setState({
                currentTrack: nextSong,
                currentTrackIndex: index,
            })
        }
    }

    _seekTo(value) {
        var percentage = value * 100;

        var newValue = (percentage / 100) * this.rap.audioEl.duration
        this.rap.audioEl.currentTime = newValue;
    }

    _timeHandler() {

        this.setState({
            audioCurrentTimeHtml: this._getTime(this.rap.audioEl.currentTime),
            audioDurationHtml: this._getTime(this.rap.audioEl.duration),
            percentagePlayed: Math.floor(this.rap.audioEl.currentTime / this.rap.audioEl.duration * 100),
            playing: true
        })

    }

    _getTime = time => {
        if (!isNaN(time)) {
            return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
        }
    }

    _togglePreSave(e) {
        e.preventDefault();
        window.open('https://madibanja.lnk.to/presave', '_blank')
    }

    render() {

        const {
            tracks,
            currentTrackIndex,
            currentTrack,
            playing,
            percentagePlayed,
            init,
            audioCurrentTimeHtml,
            audioDurationHtml,
            displayLyrics,
            currentLyricIndex
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <>
                <ReactPlayer
                    src={currentTrack}
                    autoPlay={playing}
                    ref={e => this.rap = e}
                    onListen={() => this._timeHandler()}
                    listenInterval={100}
                    onEnded={() => this._nextSong(currentTrackIndex + 1)}
                />
                {/* <BrowserView style={{ height: '100%' }}>
                    <Grid container spacing={0} style={{ height: '100%' }}>
                        <Grid item xs={6} style={{ position: 'relative' }}>
                            <div className={classes.leftContentWrapper}>
                                {!displayLyrics ? <Artwork classes={classes} /> : <Lyrics key={currentTrackIndex} _toggleLyrics={this._toggleLyrics} classes={classes} currentTrackIndex={currentTrackIndex} tracks={tracks} currentLyricIndex={currentLyricIndex} /> }
                            </div>
                            <div style={{ position: 'absolute', top: 0, height: '100%', width: 32 }}>
                                <Grid container spacing={0} justify="center" alignItems="center" alignContent="center" style={{ height: '100%' }}>
                                    <Grid item>
                                        <Socials classes={classes} />
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ position: 'absolute', bottom: 0 }}>
                                <Grid container spacing={0} justify="center" alignItems="center" alignContent="center" style={{ height: '100%', margin: 8 }}>
                                    <Grid item style={{ marginRight: 16 }}>
                                        <img src={MsmLogo} alt="" height={30} />
                                    </Grid>
                                    <Grid item>
                                        <img src={SonyLogo} alt="" height={30} />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={6} container spacing={0} alignItems="stretch" className={classes.rightColumnWrapper}>
                            <Grid item container spacing={0} justify="center" alignItems="center" alignContent="center" className={classes.pageTitleWrapper}>
                                <Logo w={270} h={100}  />
                            </Grid>
                            <Grid item container spacing={0} justify="center" alignContent="center" style={{ width: '100%' }}>
                                <TrackList
                                    classes={classes}
                                    currentTrackIndex={currentTrackIndex}
                                    tracks={tracks}
                                    playing={playing}
                                    _init={init}
                                    _switchSong={this._switchSong} />
                                <div style={{ width: '100%', height:36, textAlign:'center', marginBottom:32 }}>
                                    {!displayLyrics && 
                                        <Button variant="outlined" onClick={ () => this._toggleLyrics()}>
                                            visa text
                                        </Button>
                                    }
                                </div>
                            </Grid>
                            <Grid item container spacing={0} justify="center" alignContent="center" style={{ width: '100%' }}>
                                <Player
                                    currentTrackIndex={currentTrackIndex}
                                    tracks={tracks}
                                    classes={classes}
                                    percentagePlayed={percentagePlayed}
                                    audioCurrentTimeHtml={audioCurrentTimeHtml}
                                    audioDurationHtml={audioDurationHtml}
                                    playing={playing}
                                    _nextSong={this._nextSong}
                                    _seekTo={this._seekTo}
                                    _togglePlayPause={this._togglePlayPause}
                                    _setPlayingState={this._setPlayingState}
                                    _init={this._init} />
                            </Grid>
                        </Grid>
                    </Grid>
                </BrowserView>
                <MobileView style={{width:'100%'}}>
                {displayLyrics ? 
                    <div className={classes.leftContentWrapper}>
                        <Lyrics key={currentTrackIndex} _toggleLyrics={this._toggleLyrics} classes={classes} currentTrackIndex={currentTrackIndex} tracks={tracks} currentLyricIndex />
                    </div>
                    :
                    <Grid container spacing={0} alignItems="stretch" className={classes.mobileWrapper}>
                    <Grid item container spacing={0} alignItems="center" justify="center" alignContent="center" className={classes.pageTitleWrapper}>
                        <Grid item xs={6} container spacing={0} justify="center" alignContent="center" alignItems="center">
                            <Logo w={150} h={60} />
                        </Grid>
                        <Grid item xs={6} container spacing={0} justify="center" alignContent="center" alignItems="center">
                            <Button variant="outlined">
                                PRE-SAVE
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={0} justify="center" alignContent="center" style={{ width: '100%' }}>
                        <TrackList
                            classes={classes}
                            currentTrackIndex={currentTrackIndex}
                            tracks={tracks}
                            playing={playing}
                            _init={init}
                            _switchSong={this._switchSong}
                            _toggleLyrics={this._toggleLyrics} />
                    </Grid>
                    <Grid item container spacing={0} justify="center" alignContent="center" alignItems="center" style={{ width: '100%' }}>
                        <Player
                            currentTrackIndex={currentTrackIndex}
                            tracks={tracks}
                            classes={classes}
                            percentagePlayed={percentagePlayed}
                            audioCurrentTimeHtml={audioCurrentTimeHtml}
                            audioDurationHtml={audioDurationHtml}
                            playing={playing}
                            _nextSong={this._nextSong}
                            _seekTo={this._seekTo}
                            _togglePlayPause={this._togglePlayPause}
                            _setPlayingState={this._setPlayingState}
                            _init={this._init} />
                    </Grid>
                    <div style={{height:100, width:'100%', display:'block'}}></div>
                </Grid>
                }
                </MobileView> */}

                <AuthUserContext.Consumer>
                    {
                        authUser => !authUser
                            ?
                            <LoginForm classes={classes} />
                            :
                            <>
                <BrowserView style={{ height: '100%' }}>
                    <Grid container spacing={0} style={{ height: '100%' }}>
                        <Grid item xs={6} style={{ position: 'relative' }}>
                            <div className={classes.leftContentWrapper}>
                                {!displayLyrics ? <Artwork classes={classes} /> : <Lyrics key={currentTrackIndex} _toggleLyrics={this._toggleLyrics} classes={classes} currentTrackIndex={currentTrackIndex} tracks={tracks} currentLyricIndex={currentLyricIndex} /> }
                            </div>
                            <div style={{ position: 'absolute', top: 0, height: '100%', width: 32, zIndex:1100 }}>
                                <Grid container spacing={0} justify="center" alignItems="center" alignContent="center" style={{ height: '100%' }}>
                                    <Grid item>
                                        <Socials classes={classes} />
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ position: 'absolute', bottom: 0, zIndex:1100 }}>
                                <Grid container spacing={0} justify="center" alignItems="center" alignContent="center" style={{ height: '100%', margin: 8 }}>
                                    <Grid item style={{ marginRight: 16 }}>
                                        <img src={MsmLogo} alt="" height={30} />
                                    </Grid>
                                    <Grid item>
                                        <img src={SonyLogo} alt="" height={30} />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={6} container spacing={0} alignItems="stretch" className={classes.rightColumnWrapper} style={{position:'relative'}}>
                            <Grid item container spacing={0} justify="center" alignItems="center" alignContent="center" className={classes.pageTitleWrapper}>
                                <div className={classes.releaseInfoWrapper}>
                                    <Typography variant="caption">Release 14 juni</Typography>
                                </div>
                                <div className={classes.preSaveButtonWrapper}>
                                    <Button variant="outlined" size="small" onClick={this._togglePreSave}>
                                        <SpotifyIcon />
                                        <span style={{marginLeft:16}}>pre-save</span>
                                    </Button>
                                </div>
                                <Logo w={270} h={100}  />
                            </Grid>
                            <Grid item container spacing={0} justify="center" alignContent="center" style={{ width: '100%' }}>
                                <TrackList
                                    classes={classes}
                                    currentTrackIndex={currentTrackIndex}
                                    tracks={tracks}
                                    playing={playing}
                                    _init={init}
                                    _switchSong={this._switchSong} />
                                <div style={{ width: '100%', height:36, textAlign:'center'}}>
                                    {!displayLyrics && 
                                        <Button variant="outlined" onClick={ () => this._toggleLyrics(currentTrackIndex)}>
                                            visa text
                                        </Button>
                                    }
                                </div>
                            </Grid>
                            {/* <Grid item container spacing={0} justify="center" alignItems="center" alignContent="center">
                                <Button variant="outlined" size="small" style={{margin:'0 auto'}}>
                                    <SpotifyIcon />
                                    <span style={{marginLeft:16}}>pre-save</span>
                                </Button>
                            </Grid> */}
                            <Grid item container spacing={0} justify="center" alignContent="center" style={{ width: '100%' }}>
                                <Player
                                    currentTrackIndex={currentTrackIndex}
                                    tracks={tracks}
                                    classes={classes}
                                    percentagePlayed={percentagePlayed}
                                    audioCurrentTimeHtml={audioCurrentTimeHtml}
                                    audioDurationHtml={audioDurationHtml}
                                    playing={playing}
                                    _nextSong={this._nextSong}
                                    _seekTo={this._seekTo}
                                    _togglePlayPause={this._togglePlayPause}
                                    _setPlayingState={this._setPlayingState}
                                    _init={this._init} />
                            </Grid>
                        </Grid>
                    </Grid>
                </BrowserView>
                <MobileView style={{width:'100%', height:'100%'}}>
                {displayLyrics ? 
                    <div className={classes.leftContentWrapper}>
                        <Lyrics key={currentTrackIndex} _toggleLyrics={this._toggleLyrics} classes={classes} currentTrackIndex={currentTrackIndex} tracks={tracks} currentLyricIndex={currentLyricIndex} />
                    </div>
                    :
                    <Grid container spacing={0} alignItems="stretch" className={classes.mobileWrapper}>
                    <Grid item container spacing={0} alignItems="center" justify="center" alignContent="center" className={classes.pageTitleWrapper}>
                        <Grid item xs={6} container spacing={0} justify="center" alignContent="center" alignItems="center">
                            <Logo w={150} h={60} />
                        </Grid>
                        <Grid item xs={6} container spacing={0} justify="center" alignContent="center" alignItems="center">
                            <Grid item>
                                <Button variant="outlined" style={{marginLeft:8}} onClick={this._togglePreSave}>
                                    <SpotifyIcon />
                                    <span style={{marginLeft:16}}>PRE-SAVE</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={0} justify="center" alignContent="center" style={{ width: '100%' }}>
                        <TrackList
                            classes={classes}
                            currentTrackIndex={currentTrackIndex}
                            currentLyricIndex={currentLyricIndex}
                            tracks={tracks}
                            playing={playing}
                            _init={init}
                            _switchSong={this._switchSong}
                            _toggleLyrics={this._toggleLyrics} />
                    </Grid>
                    <Grid item container spacing={0} justify="center" alignContent="center" alignItems="center" style={{ width: '100%' }}>
                        <Player
                            currentTrackIndex={currentTrackIndex}
                            tracks={tracks}
                            classes={classes}
                            percentagePlayed={percentagePlayed}
                            audioCurrentTimeHtml={audioCurrentTimeHtml}
                            audioDurationHtml={audioDurationHtml}
                            playing={playing}
                            _nextSong={this._nextSong}
                            _seekTo={this._seekTo}
                            _togglePlayPause={this._togglePlayPause}
                            _setPlayingState={this._setPlayingState}
                            _init={this._init} />
                    </Grid>
                    <Grid item container spacing={0} justify="center" alignItems="center" alignContent="center">
                        <Socials classes={classes} />
                        <Typography variant="caption">Release 14 juni</Typography>
                    </Grid>
                    {/* <div style={{width:'100%', display:'block',marginBottom:100}}></div> */}
                </Grid>
                }
                </MobileView>
                            </>
                    }
                </AuthUserContext.Consumer>
            </>
        )
    }
}

export default compose(
    withAuthentication,
    withStyles(styles)
)(App)
