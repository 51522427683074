import React from 'react'
import { Grid, Tooltip } from '@material-ui/core';
import FacebookIcon from './FacebookIcon';
import InstagramIcon from './InstagramIcon';
import EmailIcon from './EmailIcon';

export default function Socials(props) {
    
    const {
        classes
    } = props;

    return (
        <Grid container spacing={0} justify="center" alignContent="center" alignItems="center" wrap="wrap" style={{marginLeft:16}}>
            <Grid item className={classes.socialItem}>
                <Tooltip title="isabel.rogriguez@sonymusic.com" placement="right">
                    <a className={classes.socialIcon} href="mailto:isabel.rodriguez@sonymusic.com"><EmailIcon /></a>
                </Tooltip>
            </Grid>
            <Grid item className={classes.socialItem} style={{margin:'16px 0'}}>
                <Tooltip title="@madibanja" placement="right">
                    <a className={classes.socialIcon} href="https://instagram.com/madibanja" target="_blank" rel="noopener noreferrer"><InstagramIcon /></a>
                </Tooltip>
            </Grid>
            <Grid item className={classes.socialItem}>
                <Tooltip title="@madibanja" placement="right">
                    <a className={classes.socialIcon} href="https://www.facebook.com/madibanja/" target="_blank" rel="noopener noreferrer"><FacebookIcon /></a>
                </Tooltip>
            </Grid>
        </Grid>
    )
}
