import React from 'react'

export default function PreviousIcon(props) {
    const {
        size
    } = props;
    return (
        <div style={{ width: size, height: size }}>
            <svg id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57 57' fill="#fff"  width={size} height={size}>
                <path d='M0.4,29.3l27,19c0.3,0.2,0.7,0.2,1,0.1c0.3-0.2,0.5-0.5,0.5-0.9V29.7l26.4,18.6c0.3,0.2,0.7,0.2,1,0.1	c0.3-0.2,0.5-0.5,0.5-0.9v-38c0-0.4-0.2-0.7-0.5-0.9c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4,0.1-0.6,0.2L29,27.3V9.5	c0-0.4-0.2-0.7-0.5-0.9c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4,0.1-0.6,0.2l-27,19C0.2,27.9,0,28.2,0,28.5S0.2,29.1,0.4,29.3z M55,11.4v34.1L30.7,28.5L55,11.4z M27,11.4v34.1L2.7,28.5L27,11.4z'
                />
            </svg>
        </div>
    )
}
