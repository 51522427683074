import React from 'react'
import { withFirebase } from '../Firebase';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import axios from 'axios';
import PulseLoader from 'react-spinners/PulseLoader'
import Logo from '../Logo'
import {
    Grid,
    Typography,
    Input,
    Button
} from '@material-ui/core';

class LoginForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            password: '',
            postUrl: 'https://us-central1-madibanja-prelisten.cloudfunctions.net/handleAuth/login',
            // postUrl: 'http://localhost:5000/madibanja-prelisten/us-central1/handleAuth/login',
            error: null
        }

        this.axiosCancelSource = null;

        this._handleSubmit = this._handleSubmit.bind(this);
        this._onChange = this._onChange.bind(this);

    }

    componentWillUnmount() {
        if (this.axiosCancelSource) {
            this.axiosCancelSource.cancel();
        }
    }

    _handleSubmit(e) {

        e.preventDefault();

        const {
            password,
            postUrl,
        } = this.state;

        if (password !== '') {

            this.setState({
                loading: true
            })

            this.axiosCancelSource = axios.CancelToken.source()

            axios.post(postUrl, {
                password,
                cancelToken: this.axiosCancelSource
            }).then(res => {
                if (res.data.status === 'success') {

                    this.props.firebase.doSignInAnonymously()

                } else {
                    this.setState({
                        loading: false
                    })
                }
            }).catch( err => {
                this.setState({
                    error: err
                })
            })
        }
    }

    _onChange(e) {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {

        const {
            password,
            loading
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <Grid container spacing={0} justify="center" alignContent="center" alignItems="center" className={classes.loginWrapper}>
                <Grid item className={classes.loginGridItem} container spacing={0} justify="center">
                    {/* <Typography variant="h6" className={classes.pageTitle}>MADI BANJA <br /> VÄLSIGNELSEN</Typography> */}
                    <Logo w={240} h={70} />
                </Grid>
                <Grid item >
                    <form onSubmit={this._handleSubmit}>
                        <Grid container spacing={0} justify="center" alignItems="center">
                            {
                                loading
                                ?
                                    <PulseLoader
                                        sizeUnit={"px"}
                                        height={20}
                                        width={2}
                                        radius={2}
                                        color={'#fff'}
                                        css={{
                                            display:'block',
                                            padding: '24px 0'
                                        }}
                                    />
                                :
                                <>
                                    <Grid item className={classes.loginGridItem}>
                                        <Input
                                            type="password"
                                            name="password"
                                            value={password}
                                            classes={{
                                                input: classes.loginInput
                                            }}
                                            onChange={this._onChange}/>
                                    </Grid>
                                    <Grid item className={classes.loginGridItem}>
                                        <Button
                                            variant="outlined" 
                                            type="submit"
                                            >
                                                LOGGA IN
                                        </Button>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withFirebase,
)(LoginForm)