import React from 'react'

export default function Logo(props) {

    const {
        w,h
    } = props;

    return (
        <div style={{width:w, height:h}}>
            <svg id='STICKER_1_' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 283.5 108' fill="#fff" width={w} height={h} >
                <g id='MADI_BANJA'>
                    <path className='st0' d='M27,35.4L22.6,24l0.2,11.5l-10.2,0.2l-0.4-20.9l14.4-0.2l3.3,8.6l3-8.7l14.5-0.2L47.8,35l-10.2,0.2l-0.2-11.5 l-4,11.6L27,35.4z'
                    />
                    <path className='st0' d='M49.6,35l4.8-21l17.9-0.3l5.5,20.8l-11.8,0.2l-0.7-4.5l-3.3,0.1l-0.6,4.5L49.6,35z M62.3,25.7l2.4,0l-1.3-7.1 L62.3,25.7z'
                    />
                    <path className='st0' d='M79.5,34.5l-0.4-20.9l18.7-0.3c1.3,0,2.4,0,3.3,0.2c0.9,0.2,1.7,0.4,2.3,0.8s1,0.8,1.3,1.4 c0.3,0.6,0.4,1.3,0.4,2l0.2,11.4c0,1.9-0.5,3.2-1.5,4c-0.5,0.4-1.2,0.7-2.2,0.9c-0.9,0.2-2.1,0.3-3.5,0.3L79.5,34.5z M90.8,29.5 l1.3,0c0.6,0,1.1-0.1,1.3-0.3s0.3-0.6,0.3-1.2l-0.1-8.4c0-0.6-0.1-1-0.4-1.2s-0.7-0.3-1.3-0.3l-1.3,0L90.8,29.5z'
                    />
                    <path className='st0' d='M107.7,34l-0.4-20.9l11.4-0.2l0.4,20.9L107.7,34z'
                    />
                    <path className='st0' d='M134.7,33.6l-0.4-20.9l18.5-0.3c1.3,0,2.5,0.1,3.4,0.2c0.9,0.2,1.7,0.5,2.3,0.8c0.6,0.4,1,0.9,1.3,1.5 c0.3,0.6,0.4,1.4,0.5,2.3c0,1.4-0.3,2.5-1,3.2c-0.7,0.8-1.9,1.3-3.6,1.6c1.7,0.3,2.9,0.8,3.7,1.7c0.8,0.9,1.2,2.2,1.2,4 c0,2-0.5,3.4-1.6,4.2c-1.1,0.8-3,1.3-5.7,1.3L134.7,33.6z M145.8,17.3l0,3l1.3,0c0.6,0,1-0.1,1.3-0.4c0.2-0.2,0.4-0.6,0.4-1.1 c0-0.6-0.1-1-0.4-1.2c-0.2-0.2-0.7-0.3-1.3-0.3L145.8,17.3z M146,28.6l1.3,0c0.6,0,1.1-0.1,1.3-0.3c0.2-0.2,0.3-0.6,0.3-1.2l0-0.8 c0-0.5-0.1-0.9-0.4-1.2c-0.2-0.2-0.7-0.3-1.3-0.3l-1.3,0L146,28.6z'
                    />
                    <path className='st0' d='M161.7,33.1l4.8-21l17.9-0.3l5.5,20.8l-11.8,0.2l-0.7-4.5l-3.3,0.1l-0.6,4.5L161.7,33.1z M174.4,23.8l2.4,0 l-1.3-7.1L174.4,23.8z'
                    />
                    <path className='st0' d='M191.6,32.6l-0.3-20.9l11.3-0.2l4.6,8.5l-0.1-8.6l10.4-0.2l0.3,20.9L207,32.4l-5.2-9.7l0.2,9.8L191.6,32.6z'
                    />
                    <path className='st0' d='M245,10.8l0.3,16c0,1.9-0.5,3.2-1.5,3.9c-1,0.8-2.9,1.2-5.6,1.2l-11.2,0.2c-1.3,0-2.3-0.1-3.2-0.2 c-0.9-0.2-1.6-0.4-2.2-0.8c-0.6-0.4-1-0.9-1.2-1.5c-0.3-0.6-0.4-1.4-0.4-2.3l-0.1-3.9l11.1-0.2l0,2.7c0,0.5,0.1,0.9,0.4,1.1 s0.6,0.3,1.1,0.3c0.5,0,0.9-0.1,1.1-0.4s0.3-0.6,0.3-1.2L233.6,11L245,10.8z'
                    />
                    <path className='st0' d='M246.8,31.7l4.8-21l17.9-0.3l5.5,20.8l-11.8,0.2l-0.7-4.5l-3.3,0.1l-0.6,4.5L246.8,31.7z M259.5,22.4l2.4,0 l-1.3-7.1L259.5,22.4z'
                    />
                </g>
                <g id='VALSIGNELSEN'>
                    <path className='st0' d='M14.6,103.8L8.7,85.3l10.6-0.2l2,11.8L22.9,85l10.6-0.2l-5.3,18.7L14.6,103.8z'
                    />
                    <path className='st0' d='M32.5,103.5l4.3-18.7l15.9-0.3l4.9,18.6l-10.5,0.2l-0.7-4l-2.9,0l-0.5,4L32.5,103.5z M43.9,95.2l2.2,0 l-1.2-6.3L43.9,95.2z'
                    />
                    <path className='st0' d='M59.2,103l-0.3-18.6l10.2-0.2l0.2,14.4l6.3-0.1l0.1,4.2L59.2,103z'
                    />
                    <path className='st0' d='M83.8,102.8c-2.1,0-3.7-0.2-4.8-0.8c-1.1-0.6-1.6-1.4-1.7-2.6L77.3,96l10.4-0.2l0,1.3c0,0.9,0.4,1.3,1.3,1.3 c0.4,0,0.8-0.1,1-0.3c0.2-0.2,0.3-0.6,0.3-1.1c0-0.3,0-0.5-0.1-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.3-0.5-0.4 c-0.2-0.1-0.5-0.2-0.9-0.3l-7.1-1.9c-1.6-0.4-2.8-0.9-3.3-1.5c-0.6-0.6-0.9-1.3-0.9-2.2l0-2c0-1.1,0.5-2,1.6-2.6 c1.1-0.6,2.6-0.9,4.8-1l10.5-0.2c2.1,0,3.7,0.2,4.8,0.8c1.1,0.6,1.6,1.4,1.6,2.6l0,2.2l-10.3,0.2l0-0.2c0-0.8-0.5-1.3-1.4-1.2 c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.6,0.3 c0.2,0.1,0.6,0.2,1,0.3l8.3,2.3c0.5,0.2,1,0.3,1.3,0.6c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.4,0.2,0.9,0.2,1.5 l0,2.1c0,1.4-0.5,2.4-1.5,3.1c-1,0.7-2.6,1-4.8,1L83.8,102.8z'
                    />
                    <path className='st0' d='M102.7,102.3l-0.3-18.6l10.2-0.2l0.3,18.6L102.7,102.3z'
                    />
                    <path className='st0' d='M127.7,95.3l-1.4,0l-0.1-4.2L138,91l0.1,7.4c0.1,2.3-1.4,3.5-4.5,3.5l-7.1,0.1l-5.5,0.1c-1.1,0-2.1,0-2.9-0.2 c-0.8-0.1-1.4-0.4-1.9-0.7c-0.5-0.3-0.9-0.8-1.1-1.4c-0.2-0.6-0.4-1.2-0.4-2.1l-0.2-10.4c0-1.4,0.5-2.4,1.4-3.1c1-0.6,2.5-1,4.7-1 l10.6-0.2c1.2,0,2.2,0,3,0.2s1.5,0.4,2,0.7c0.5,0.3,0.9,0.7,1.2,1.2c0.2,0.5,0.4,1.1,0.4,1.8l0,1.9L127.6,89l0-0.3 c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.6-0.3-1-0.3c-0.5,0-0.8,0.1-1,0.3c-0.2,0.2-0.3,0.6-0.3,1 l0.1,7.8c0,0.5,0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,1,0.3s0.8-0.1,1-0.3s0.3-0.6,0.3-1L127.7,95.3z'
                    />
                    <path className='st0' d='M140.3,101.7L140,83.1l10-0.2l4.1,7.6l-0.1-7.7l9.3-0.2l0.3,18.6l-9.6,0.2l-4.6-8.7l0.1,8.8L140.3,101.7z'
                    />
                    <path className='st0' d='M165.8,101.3l-0.3-18.6l20.2-0.3l0.1,4.2l-10,0.2l0,2l9.1-0.2l0.1,4.2l-9.1,0.2l0.1,3.9l10-0.2l0.1,4.2 L165.8,101.3z'
                    />
                    <path className='st0' d='M188.3,100.9L188,82.3l10.2-0.2l0.2,14.4l6.3-0.1l0.1,4.2L188.3,100.9z'
                    />
                    <path className='st0' d='M212.9,100.6c-2.1,0-3.7-0.2-4.8-0.8c-1.1-0.6-1.6-1.4-1.7-2.6l-0.1-3.4l10.4-0.2l0,1.3 c0,0.9,0.4,1.3,1.3,1.3c0.4,0,0.8-0.1,1-0.3c0.2-0.2,0.3-0.6,0.3-1.1c0-0.3,0-0.5-0.1-0.7c0-0.2-0.1-0.4-0.2-0.5 c-0.1-0.1-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.2-0.9-0.3l-7.1-1.9c-1.6-0.4-2.8-0.9-3.3-1.5c-0.6-0.6-0.9-1.3-0.9-2.2l0-2 c0-1.1,0.5-2,1.6-2.6c1.1-0.6,2.6-0.9,4.8-1l10.5-0.2c2.1,0,3.7,0.2,4.8,0.8c1.1,0.6,1.6,1.4,1.6,2.6l0,2.2l-10.3,0.2l0-0.2 c0-0.8-0.5-1.3-1.4-1.2c-0.4,0-0.7,0.1-0.9,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.3,0.3 c0.1,0.1,0.3,0.2,0.6,0.3c0.2,0.1,0.6,0.2,1,0.3l8.3,2.3c0.5,0.2,1,0.3,1.3,0.6s0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1.1 c0.1,0.4,0.2,0.9,0.2,1.5l0,2.1c0,1.4-0.5,2.4-1.5,3.1c-1,0.7-2.6,1-4.8,1L212.9,100.6z'
                    />
                    <path className='st0' d='M231.8,100.2l-0.3-18.6l20.2-0.3l0.1,4.2l-10,0.2l0,2l9.1-0.2l0.1,4.2l-9.1,0.2l0.1,3.9l10-0.2l0.1,4.2 L231.8,100.2z'
                    />
                    <path className='st0' d='M254.3,99.8l-0.3-18.6l10-0.2l4.1,7.6l-0.1-7.7l9.3-0.2l0.3,18.6l-9.6,0.2l-4.6-8.7l0.1,8.8L254.3,99.8z'
                    />
                </g>
                <g id='_x2E_.'>
                    <path className='st0' d='M42.5,75.9c0.9,0.9,1.3,1.9,1.4,3.1s-0.4,2.3-1.3,3.2c-0.9,0.9-1.9,1.3-3.2,1.4c-1.2,0-2.3-0.4-3.2-1.3 c-0.9-0.9-1.3-1.9-1.3-3.2c0-1.2,0.4-2.3,1.2-3.2c0.9-0.9,1.9-1.4,3.2-1.4C40.6,74.6,41.6,75,42.5,75.9z'
                    />
                    <path className='st0' d='M53.5,75.7c0.9,0.9,1.3,1.9,1.4,3.1s-0.4,2.3-1.3,3.2c-0.9,0.9-1.9,1.3-3.2,1.4c-1.2,0-2.3-0.4-3.2-1.3 c-0.9-0.9-1.3-1.9-1.3-3.2c0-1.2,0.4-2.3,1.2-3.2c0.9-0.9,1.9-1.4,3.2-1.4C51.5,74.4,52.6,74.8,53.5,75.7z'
                    />
                </g>
            </svg>
        </div>
    )
}
