import React from "react";
import ReactDOM from "react-dom";
import Firebase, { FirebaseContext } from './Components/Firebase';
import App from "./Components/App";
import * as serviceWorker from "./serviceWorker";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import './assets/style.scss';

const theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#fff"
      },
      secondary: {
        main: "#000"
      },
      error: red
    },
    typography: {
      useNextVariants: true,
      // fontFamily: '"SpaceGrotesk-Bold"',
      h5: {
        fontFamily: '"SpaceGrotesk-Bold"'
      },
      button: {
        fontFamily: '"SpaceGrotesk-Bold"',
        letterSpacing: 1.7
      },
      body2: {
        fontFamily: '"SpaceGrotesk-Bold"',
        letterSpacing: 1.7
      },
      h6:{
        fontFamily: '"UniversLTStd-Bold"'
      },
      caption: {
        fontFamily: '"SpaceGrotesk-Bold"'
      }
    }
  });
  
  ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </MuiThemeProvider>
    </FirebaseContext.Provider>,
    document.getElementById("root")
  );
  
  serviceWorker.unregister();